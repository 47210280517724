import Features from "../../../../Component/Features/Features";
import ReelSlider from "../../../../Component/ReelSlider/ReelSlider";
import './HomeReelSection.css'
const HomeReelSection = ({features})=>
{
    return(
        <div className="home-reel-section">
            <Features  features={features}/>
            <ReelSlider />
            <h3>
                 White Keyboard with Acrylic Dust Cover: Where Form Meets Function
            </h3>
        </div>
    )
}

export default HomeReelSection;