import React from 'react';
import "../../App.css";
import './Home.css';
import { Gallery } from "../../Component";
import FloatingCTA from "../../Component/FloatingButton/Button";
import HeroSection from './component/HeroSection/HeroSection';
import data from "../../data.json";
import HomeRichText from './component/HomeRichText/HomeRichText';
import HomeGrid from './component/HomeGrid/HomeGrid';
import NavBar from '../ArionLightTheme/Components/NavBar/NavBar'
import HomeReelSection from './component/HomeReelSection/HomeReelSection';
import InTheBox from './component/InTheBox/InTheBox';
import BuyNow from './component/BuyNow/BuyNow';
import Footer from '../ArionLightTheme/Components/Footer/Footer'
function Home() {
  return (
    <div className="meckeys-parent-container">
      <NavBar />
      <HeroSection />
      <HomeRichText text={data.homeRichText}/>
      <FloatingCTA data={data.FloatingCTA} />
      <HomeGrid />
      <HomeReelSection features={data.colData}/>
      <Gallery data={data.GalleryData} />
      <InTheBox />
      <BuyNow />
      {/* <Footer /> */}
    </div>
  )
}

export default Home