import React from "react";
import './HomeRichText.css';
const HomeRichText = ({text})=>
{
    return(
        <div className="home-rich-text">
            <h2>
               {text}
            </h2>
        </div>
    )
}
export default HomeRichText;