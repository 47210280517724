import React from 'react';
import styles from './herosection.module.css';
import KeyboardImage from '../../../../assets/arionlight/herosectionkeyboard.svg';
import ArrowRight from '../../../../assets/arionlight/arrow-right.svg';

const HeroSection = () => {
  return (
    <div className={styles.heroContainer}>
      <h1 className={styles.title}>
        Arion brings you most durable & premium <br />
        <span className={styles.highlight}> Keyboards and Accessories</span>
      </h1>
      <div className={styles.contentWrapper}>
          <img src={KeyboardImage} alt="Arion FROST Keyboard" className={styles.keyboardImage} />
        <div className={styles.productCard}>
          <span className={styles.tag}>New</span>
          <div>
            <h2 className={styles.productName}>Arion FROST</h2>
            <p className={styles.productDescription}>
              A masterpiece of modern engineering and design that's set to redefine your keyboard experience.
            </p>
          </div>
          <button className={styles.ctaButton}>Show Me <img src={ArrowRight} height={16} width={16}></img></button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;