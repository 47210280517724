import React from 'react'
import './WhyArion.css'
import img1 from './assets/img1.jpeg'
import img2 from './assets/img2.jpeg'
import img3 from './assets/img3.jpeg'
import img4 from './assets/img4.jpeg'
import img5 from './assets/img5.jpeg'
import img6 from './assets/img6.jpeg'
export const WhyArion = () => {
    return (
        <div className='why-arion'>
            <div className='container'>
                <img width={397} height={284} src={img1} className='img1' alt='image' />
                <img width={290} height={177} src={img2} className='img2' alt='image' />
                <img width={360} height={257} src={img3} className='img3' alt='image' />
                <img width={384} height={213} src={img4} className='img4' alt='image' />
                <img width={301} height={215} src={img5} className='img5' alt='image' />
                <img width={293} height={224} src={img6} className='img6' alt='image' />
                <h2>
                    Why Arion is the best place to buy gaming accessories.
                </h2>
                <p>
                    At Meckeys, we’re gamers first, so we know exactly what you need for that edge. Our accessories are built for top-tier performance and durability, giving you the ultimate gaming experience. If you're serious about your game, trust us—because we’re serious about ours.
                </p>
            </div>
        </div>
    )
}

export default WhyArion;