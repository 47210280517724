import React from "react";
import './BuyNow.css';
import KeyBoardImg from '../../../../assets/arionlight/herosectionkeyboard.png'
const BuyNow = ()=>
{
    return(
        <div className="home-buy-now">
            <a here="/">
                Buy Now
            </a>    
            <img src={KeyBoardImg} alt="keyboard" />
        </div>
    )
}
export default BuyNow