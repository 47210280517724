import React from "react";
import { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; 
import 'swiper/css/navigation';  
import { Navigation } from 'swiper/modules';
import './ReelSlider.css'
import reel from './assets/reels.mp4'
import ArrowLeft from './assets/arrow-left.svg'
import ArrowRight from './assets/arrow-right.svg'
const contents = [
    {
        title: 'Arion Frost',
        link: "",
        video: reel
    },
    {
        title: 'Dope Motions',
        link: "",
        video: reel
    },
    {
        title: 'Trick Pilot',
        link: "",
        video: reel
    },
    {
        title: 'Ratin Ray',
        link: "",
        video: reel
    },
    {
        title: 'Arion Frost',
        link: "",
        video: reel
    },
    {
        title: 'Dope Motions',
        link: "",
        video: reel
    }
];

const ReelSlider = () => {
    const videosRef = useRef([]);
    const [hasInteracted, setHasInteracted] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const prevButtonRef = useRef(null); // Ref for previous button
    const nextButtonRef = useRef(null); // Ref for next button

    // Function to handle video interaction on the page to enable hover functionality
    const handleInteraction = () => {
        setHasInteracted(true);
        window.removeEventListener('click', handleInteraction);
        window.removeEventListener('scroll', handleInteraction);
    };

    // Add event listeners to track user interaction
    useEffect(() => {
        window.addEventListener('click', handleInteraction);
        window.addEventListener('scroll', handleInteraction);

        // Cleanup event listeners on unmount
        return () => {
            window.removeEventListener('click', handleInteraction);
            window.removeEventListener('scroll', handleInteraction);
        };
    }, []);

    // Function to play the video on hover
    const handleMouseEnter = (video) => {
        if (hasInteracted) {
            video.muted = false; // Unmute the video
            video.play().catch((error) => {
                console.error('Video play failed due to autoplay restrictions:', error);
            });
            video.classList.add('active');
        }
    };

    // Function to pause the video when the hover ends
    const handleMouseLeave = (video) => {
        video.pause();
        video.muted = true; // Mute the video again
        video.classList.remove('active');
    };

    const handleSlideChange = (swiper) => {
        if (isMobileView) {
            // Pause all videos
            videosRef.current.forEach(video => {
                video.pause();
                video.classList.remove('active');
            });
            // Autoplay the center slide video
            const centerIndex = swiper.activeIndex;
            const video = videosRef.current[centerIndex];
            video.classList.add('active')
            if (video) {
                video.play().catch(error => console.error('Autoplay failed:', error));
            }
        }
    };

    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);

        // Autoplay the first video if in mobile view
        if (isMobileView && videosRef.current[0]) {
            const firstVideo = videosRef.current[0];
            firstVideo.play().catch(error => console.error('Autoplay failed:', error));
            firstVideo.classList.add('active');
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobileView]);


    return (
        <div className="reel-slider">
           <div ref={prevButtonRef} class="reel-button">
            <img src={ArrowLeft} width={92} height={92} alt="swipe-left" />
           </div>
            <Swiper
                modules={[Navigation]}
                spaceBetween={16}
                slidesPerView={1}
                centeredSlides={false}
                onSlideChange={handleSlideChange}
                navigation={{
                    nextEl: nextButtonRef.current,
                    prevEl: prevButtonRef.current,
                }}
                breakpoints={{
                    768: {
                        spaceBetween: 30,
                        slidesPerView: 2,
                        centeredSlides: false,
                    },
                    1200: {
                        spaceBetween: 30,
                        slidesPerView: 3,
                        centeredSlides: false,
                    }
                }}
                className="products-swiper"
            >
                {contents.map((item, index) => (
                    <SwiperSlide key={index} className="reel-card">
                        <video
                            ref={(el) => (videosRef.current[index] = el)}  // Store reference to each video
                            src={item.video}
                            muted={true}
                            loop
                            playsInline
                            className=""
                            onMouseEnter={(e) => handleMouseEnter(e.target)}  // Call handleMouseEnter on hover
                            onMouseLeave={(e) => handleMouseLeave(e.target)}  // Call handleMouseLeave when hover ends
                        />
                        <h3>{item.title}</h3>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div ref={nextButtonRef} class="reel-button">
            <img src={ArrowRight} width={92} height={92} alt="swipe-right" />
            </div>
        </div>
    )
}

export default ReelSlider;