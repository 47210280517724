import './features.gird.css'
import React from "react";
import image3 from './assets/img3.png'
const FeaturesGrid = () => {
    return (
        <div className="features-grid">
            <div className='grid'>
                <div className='box box-1'>
                    <p className='text-1'>
                        Exquisite Precision Engineering and Craftmanship
                    </p>
                </div>
                <div className='box box-2'>
                    <p className='text-2'>
                        Unparalleled Durability
                        and Longevity for Reliable Performance
                    </p>
                </div>
                <div className='box box-3'>
                    <img className='bg-image' src={image3} width={115} height={280} alt='design' />
                    <span>
                        Ergonomic design
                    </span>
                    <p className='text-3'>
                        Effortless Comfort
                    </p>
                </div>
            </div>
            <div className='grid-2'>
                <div className='box box-1'>
                    <p className='text-1'>
                        Multi-Device Pairing for Versatile Use Across Platforms
                    </p>
                </div>
                <div className='box box-2'>
                    <p className='text-1'>
                        Optimised for Maximum Productivity
                    </p>
                </div>
                <div className='box box-3'>
                    <span>
                        Built to Attract
                    </span>
                    <p className='text-3'>
                        Sleek and Modern Design to Complement Any Workplace
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FeaturesGrid;