import React from "react";
import Logo from './assets/logo.png';
import social1 from './assets/Facebook.svg'
import social2 from './assets/Twitter.svg'
import social3 from './assets/Vimeo.svg'
import social4 from './assets/Youtube.svg'
import semiCircle from './assets/semi_circle.svg'
import EmailBg from './assets/Shape.svg';
import { NavLink } from "react-router-dom";
import './footer.css';

const FooterLinks = {
    navliks: [
        {
            title: "About Us",
            link: '/'
        },
        {
            title: "Frost",
            link: '/'
        },
        {
            title: "Deskmats",
            link: '/'
        },
        {
            title: "Coiled Cables",
            link: '/'
        }
    ],
    socialLiks:[
        {
            link:"/",
            icon : social1
        },
        {
            link:"/",
            icon : social2
        },
        {
            link:"/",
            icon : social3
        },
        {
            link:"/",
            icon : social4
        }
    ],
    policiesLink: [
        {
            title: "Terms of Service",
            link: '/'
        },
        {
            title: "Privacy Policy",
            link: '/'
        }
    ]
}

const Footer = () => {
    return (
        <div className="footer">
            <img width={200} alt="bg" src={EmailBg} className="bg" />
            <div className="container">
                <form className="email-subscribe">
                    <h2>
                        Subscribe Newsletters
                    </h2>
                    <div className="input-field">
                        <input className="" name="email" type="text" placeholder="Enter your email">
                        </input>
                        <button type="submit">
                            Subcribe Now
                        </button>
                    </div>
                </form>
                <div className="footer-links">
                    <div className="links">
                        {FooterLinks.navliks.map((item, index) => (
                            <NavLink key={index} to={item.link}>
                                {item.title}
                            </NavLink>
                        ))}
                    </div>
                    <div className="social-links">
                        {FooterLinks.socialLiks.map((item, index) => (
                            <NavLink key={index} to={item.link}>
                                <img width={24} height={24} src={item.icon} alt="icons" />
                            </NavLink>
                        ))}
                    </div>
                </div>
                <div className="horizontal">

                </div>
                <div className="info">
                    <span>
                        © 2024 Arion. All rights reserved.
                    </span>
                    <img
                        width={177}
                        height={22}
                        alt="logo"
                        src={Logo}
                    />
                    <div className="policies-link">
                        <NavLink to="/">
                            Terms of Service
                        </NavLink>
                        <NavLink to="/">
                            Privacy Policy
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
