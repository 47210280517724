import React from 'react'
import NavBar from './Components/NavBar/NavBar'
import HeroSection from './Components/HeroSection/HeroSection'
import Categories from './Components/Categories/Categories'
import PopularProducts from './Components/PopularProducts/PopularProducts'
import FeaturesGrid from './Components/FeaturesGrid/FeaturesGrid'
import Testimonials from './Components/Testimonials/Testimonials'
import WhyArion from './Components/WhyArion/WhyArion'
import HearFromCustomers from './Components/HearFromCustomers/HearFromCustomers'
import Footer from './Components/Footer/Footer'
import './ArionLightTheme.css';

const ArionLightTheme = () => {
  return (
    <div className="arion-light-theme">
    <NavBar />
    <HeroSection />
    <Categories/>
    <PopularProducts />
    <FeaturesGrid />
    <Testimonials />
    <WhyArion />
    <HearFromCustomers />
    <Footer />
    </div>
  )
}

export default ArionLightTheme