import React, { useState, useEffect } from 'react';
import css from './button.module.css';
import dataJson from '../../data.json';

const FloatingCTA = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [style, setStyle] = useState({});
    const handleClick = () => {
        document.location.href = data.url;
    };
    // if there is a query string called cta = false then dont show the cta
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const cta = urlParams.get('cta');
        if (cta == 'false') {
            setStyle({
                display: 'none',
            });
        }
    }, []);

    return (
        <div
            className={`${open && css.openCTA} ${css.FloatingCTAContainer}`}
            onClick={() => {
                console.log('click');
                console.log(open);
                if (!open) {
                    setOpen(true);
                } else {
                    handleClick();
                }
            }}
            style={style}
        >
            <img
                src={dataJson.cartIcon}
                width={50}
                height={50}
                // on hover event
                onMouseEnter={() => {
                    if(window.innerWidth < 600) return;
                    setOpen(true);
                }}
                onClick={() => {
                    if (!open) {
                        setOpen(true);
                    }
                    if (open) {
                        handleClick();
                    }
                }}
            />
            <span
                dangerouslySetInnerHTML={{ __html: data.title }}
                onClick={handleClick}
            />
        </div>
    );
};

export default FloatingCTA;
