import React from "react";
import './HeroSection.css';
import KeybordImg from '../../../../assets/arionlight/herosectionkeyboard.png'
const HeroSection = () => {
    return (
        <div className="hero-section">
            <div className="container">
                <p> Meckeys x Keychron</p>
                <h1>FROST</h1>
                <img src={KeybordImg} height={431}></img>
            </div>
        </div>
    )
}
export default HeroSection;