import './Features.css';
import React from 'react';

const Features = ({features}) => {
    return (
        <div className="Features">
                {features.map((feature, index) => (
                    <div className="box" key={index}>
                        <img src={feature.image} width={150} height={150} alt={feature.title} />
                        <p className="title">{feature.title}</p>
                        <p className="subtitle"
                         dangerouslySetInnerHTML={{
                            __html: feature.subtitle,
                        }}
                        ></p>
                    </div>
                ))}
        </div>
    );
};

export default Features;