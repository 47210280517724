import React, { useState , useEffect} from 'react';
import './Testimonials.css';
import icon from './assets/icon.png';
import t1 from './assets/t1.png';
import t2 from './assets/t2.png';
import t3 from './assets/t3.png';
import t4 from './assets/t4.png';
import t5 from './assets/t5.png';
import t6 from './assets/t6.png';
import tweeter from './assets/tweeter.svg';
import check from './assets/check.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';  // Import Swiper core styles
import 'swiper/css/navigation';  // Import Navigation module styles
import 'swiper/css/pagination';  // Add this line to import pagination styles


const testimonials = {
  titleIcon: icon,
  title: "We’ve got lots of happy friends, and we’re always looking for more",
  tweets: [
    {
      name: "Smutchings",
      userImg: t1,
      tweet: "I love that <a>@Sketch</a> is now free for students and educators, making it easier and cheaper for those in education to practice using industry tools."
    },
    {
      name: "Jordan Kicklighter",
      userImg: t4,
      tweet: "Using overrides and layouts in symbols makes for a great workflow when a base set of UI pieces (form inputs, buttons, text styles, etc.) are mocked up first. Makes staying consistent with your design system way require much less thought."
    },
    {
      name: "Nick Heer",
      userImg: t2,
      tweet: "The main difference between Sketch and any of Adobe’s products is that I look forward to being creative in Sketch, while Adobe’s stuff makes me hate my choice of profession. Either company is free to use this testimonial."
    },
    {
      name: "Mac Pankiewicz",
      userImg: t5,
      tweet: "I still have <a>@Sketch</a> 2.4 installed on my wife’s MacBook Air. Good times! You guys pushed designers into something new. Had a blast switching from Photoshop to Sketch back then. It elevated my workflow to a whole new level 🚀 Thank you for building great tools 🙌"
    },
    {
      name: "Matthew",
      userImg: t3,
      tweet: "Suddenly, a decision to stay loyal to <a>@Sketch</a> all these years looks like a totally well placed bet. I officially dropped Adobe around 2014 because I fell in love with Sketch. Thank you for continuing to make the best UI design tool for Mac there is."
    },
    {
      name: "Blake",
      userImg: t6,
      tweet: "I no longer have any adobe products and live in <a>@Sketch</a> 8 hours a day. It’s wonderful"
    }
  ]
};

const Testimonials = () => {

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsDesktop(window.innerWidth >= 1200);
    };

    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);
    
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);


  return (
    <div className='testimonials'>
      <div className='container'>
        <img src={testimonials.titleIcon} className='title-icon' width={160} height={62} alt='you' />
        <h2>{testimonials.title}</h2>

        {
          isDesktop ? (
            <div className='tweets'>
              {testimonials.tweets.map((user, index) => (
                <div className='box' key={index}>
                  <div className='user'>
                    <img className='user-img' src={user.userImg} width={40} height={40} alt='user-img' />
                    <div className='user-info'>
                      <h3 className='user-name'>{user.name}</h3>
                      <div className='user-varified'>
                        <img width={18} height={18} src={check} alt='verified' />
                        <span>Verified Purchase</span>
                      </div>
                      <img className='tweeter-icon' src={tweeter} width={24} height={24} alt='twitter' />
                    </div>
                  </div>
                  <p
                    className='user-message'
                    dangerouslySetInnerHTML={{ __html: user.tweet }}
                  />
                </div>
              ))}
            </div>) : (
            <div className='slider'>
              <Swiper
                modules={[Pagination]}
                spaceBetween={16}
                slidesPerView={1.2}
                centeredSlides={true}
                className="products-swiper"
                breakpoints={{
                  768: {
                    spaceBetween: 15,
                    slidesPerView: 2,
                    centeredSlides:false,
                    slidesOffsetBefore:16
                  }
                }}
                pagination={{
                  el: '.testimonials .pagination',  
                  clickable: true, 
                  dynamicBullets: true,
                }}
              >
                {testimonials.tweets.map((user, index) => (
                  <SwiperSlide className='box' key={index}>
                    <div className='user'>
                      <img className='user-img' src={user.userImg} width={40} height={40} alt='user-img' />
                      <div className='user-info'>
                        <h3 className='user-name'>{user.name}</h3>
                        <div className='user-varified'>
                          <img width={18} height={18} src={check} alt='verified' />
                          <span>Verified Purchase</span>
                        </div>
                        <img className='tweeter-icon' src={tweeter} width={24} height={24} alt='twitter' />
                      </div>
                    </div>
                    <p
                      className='user-message'
                      dangerouslySetInnerHTML={{ __html: user.tweet }}
                    />
                  </ SwiperSlide>
                ))}
              </Swiper>        
              <div className="pagination">

              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Testimonials;
