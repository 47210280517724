import React, { useState, useEffect } from 'react';
import { Drawer, IconButton } from '@mui/material';
import styles from './navbar.module.css'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../../../../assets/arionlight/arionnavlogo.svg'
import NavArrow from '../../../../assets/arionlight/navarrow.svg'

const NavBar = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1021);
  const [openDrawer, setOpenDrawer] = useState(false);

  // useEffect to check if the screen is less than 1021px and set isDesktop state
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1020);
    };
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <nav className={styles.navbar}>
      {
        isDesktop ?
          (<div className={styles.container}>
            <div className={styles.logoContainer}>
              <img src={Logo} alt="Arion Logo" className={styles.logo} />
            </div>
            <ul className={styles.navLinks}>
              <li><a href="/" className={styles.navLink}>Home</a></li>
              <li><a href="/about" className={styles.navLink}>About Us</a></li>
              <li>
                <a href="/products" className={styles.navLink}>
                  Products <img src={NavArrow} alt="Dropdown" className={styles.dropdownArrow} />
                </a>
              </li>
            </ul>
            <button className={styles.contactButton}>Contact Us</button>
          </div>) :
          (
            <div className={styles.container}>
              <div className={styles.logoContainer}>
                <img src={Logo} alt="Arion Logo" className={styles.logo} />
              </div>
              <IconButton
                className={styles.hamburgerMenu}
                onClick={() => setOpenDrawer(true)}
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                anchor="left"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                classes={{ paper: styles.customDrawer }}
              >
                <div className={styles.drawerContent}>
                  <IconButton
                    className={styles.closeButton}
                    onClick={() => setOpenDrawer(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <ul className={styles.navLinks}>
                    <li>
                      <a href="/" className={styles.navLink}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/about" className={styles.navLink}>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="/products" className={styles.navLink}>
                        Products <img src={NavArrow} alt="Dropdown" className={styles.dropdownArrow} />
                      </a>
                    </li>
                    <button className={styles.contactButton}>Contact Us</button>
                  </ul>
                </div>
              </Drawer>
            </div>
          )
      }

    </nav>
  )
}

export default NavBar