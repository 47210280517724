import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';  // Import Swiper core styles
import 'swiper/css/navigation';  // Import Navigation module styles
import './popular.products.css';  // Assuming you have your own custom styles

// Import Swiper core and required modules
import { Navigation } from 'swiper/modules';

// Import product images
import img1 from './assets/image5.png';
import img2 from './assets/image6.png';
import img3 from './assets/image7.png';
import img4 from './assets/image8.png';
import img5 from './assets/image9.png';
import arrow from './assets/arrow.svg'
const slideOffset = `${window.innerWidth / 2 - 621}px`;

// Popular products data
const popularProducts = [
  {
    title: 'Arion Frost',
    image: img1,
    tag: 'new',
    categories: ['Wireless', 'Hot-swap'],
    description: 'Ultimate keyboard upgraded (special edition Keychron K8 Pro)',
    price: '₹10,495.00 ( incl. GST )',
    comparePrice: '₹15,000.00',
  },
  {
    title: 'Arion Deskmat',
    image: img2,
    tag: "",
    categories: ['900 x 400 x 3 (mm)'],
    description: 'Ultimate keyboard upgraded (special edition Keychron K8 Pro)',
    price: '₹1,500.00 ( incl. GST )',
    comparePrice: '₹2,500.00',
  },
  {
    title: 'Arion Coiled Cables',
    image: img3,
    tag: [],
    categories: ['900 x 400 x 3 (mm)'],
    description: 'Ultimate keyboard upgraded (special edition Keychron K8 Pro)',
    price: '₹1,600.00 ( incl. GST )',
    comparePrice: '₹2,500.00',
  },
  {
    title: 'Arion Frost Barebone',
    image: img4,
    tag: 'new',
    categories: ['Wireless', 'Hot-swap'],
    description: 'Ultimate keyboard upgraded (special edition Keychron K8 Pro)',
    price: '₹1,600.00 ( incl. GST )',
    comparePrice: '₹2,500.00',
  },
  {
    title: '90 Degree USB C',
    image: img5,
    tag: 'new',
    categories: ['Wireless', 'Hot-swap'],
    description: 'Arion USB 4.0 Adapter Male to Female, 40 GBPS data transfer, 90 Degree Angle for Ri',
    price: '₹350.00 ( incl. GST )',
    comparePrice: '₹1000.00',
  },
  {
    title: 'Arion Deskmat',
    image: img2,
    tag: "",
    categories: ['900 x 400 x 3 (mm)'],
    description: 'Ultimate keyboard upgraded (special edition Keychron K8 Pro)',
    price: '₹1,500.00 ( incl. GST )',
    comparePrice: '₹2,500.00',
  },
];

const PopularProducts = () => {
  return (
    <div className="popular-products">
      <div className="title">
        <h2>Popular Products</h2>
        <div className="slider-arrows">
          <div className="prev"><img width={24} height={24} src={arrow} /></div>
          <div className="next"><img width={24} height={24} src={arrow} /></div>
        </div>
      </div>
      <div className="slider-area">
        <Swiper
          modules={[Navigation]}
          spaceBetween={24}
          slidesPerView={1.5}
          centeredSlides={true}
          slidesOffsetBefore={-13}
          navigation={{
            nextEl: '.popular-products .next',
            prevEl: '.popular-products .prev',
          }}
          className="products-swiper"
          breakpoints={{
            768: {
              spaceBetween: 15, 
              slidesPerView:"auto",
              slidesOffsetBefore:-13,
              centeredSlides:false
            }
          }}
        >
          {popularProducts.map((product, index) => (
            <SwiperSlide key={index} className="product-card">
                { product.tag && 
                <span className="tag">{product.tag}</span>
                }
                <img src={product.image} alt={product.title} />
                <h3>{product.title}</h3>
                <div className="catagories">
                  {
                    product.categories.map((item, index) => (
                      <span key={index} className="cat-tag">
                        {item}
                      </span>
                    ))
                  }
                </div>
                <p className="description">{product.description}</p>
                <p className="price">{product.price}</p>
                {product.comparePrice && <p className="compare-price"><s>{product.comparePrice}</s></p>}
                <div className="buy-button">
                  <span>Buy Now</span>
                  <img width={24} height={24} src={arrow} />
                </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PopularProducts;
