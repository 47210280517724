import React from "react";
import img1 from './assets/img1.png'
import img2 from './assets/img2.png'
import img3 from './assets/img3.png'
import img4 from './assets/img4.png'
import img5 from './assets/img5.png'
import './HomeGrid.css'

const HomeGrid = () => {

    return (
        <div className="home-grid">
            <div className="grid grid1">
                <div className="box box1">
                    <div className="text">
                        <h3>Wired and Wireless</h3>
                        <p>Multitask with Arion Frost’s Bluetooth 5.1, connecting 3 devices wirelessly for easy switching between your laptop, phone, and tablet on Mac and Windows. Wired USB-C connection also supported.</p>
                    </div>
                    <img src={img1} width={200}></img>
                </div>
                <div className="box box2">
                    <div className="text">
                        <h3>
                            Ergonomic Support
                        </h3>
                        <p>
                            Adjust your typing angle (3.5º, 7.5º, 10.5º) and enjoy ergonomic wrist support with curved OSA-profile keycaps.
                        </p>
                    </div>
                    <img src={img2} />
                </div>
            </div>
            <div className="grid grid2">
                <div className="box box3">
                    <div className="text">
                        <h3>Pre-lubed Gateron 3.0 Switches</h3>
                    </div>
                    <img src={img3} />
                </div>
                <div className="box box4">
                    <div className="text">
                        <h3>
                            Double-Shot OSA PBT Keycaps
                        </h3>
                        <p>They keycaps offer enhanced durability, resisting shine and wear, with high-quality legends that remain crisp and clear over time since they are molded into the keycap material.</p>
                    </div>
                    <img src={img4} />
                </div>
                <div className="box box5">
                    <img src={img5} alt="deskmat" />
                </div>
            </div>
        </div>
    )
}
export default HomeGrid;