import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';  // Import Swiper core styles
import 'swiper/css/navigation';  // Import Navigation module styles
import './hear.from.customers.css';  // Assuming you have your own custom styles
import { useRef, useState, useEffect } from 'react';
// Import Swiper core and required modules
import { Navigation } from 'swiper/modules';

// import assets
import arrow from './assets/arrow.svg'
import reel from './assets/reels.mp4'
import { Link } from 'react-router-dom';

// Popular products data
const customersFeedback = [
  {
    header: "Hear from our customers",
    feedbacks: [
      {
        title: 'Arion Frost',
        link: "",
        video: reel
      },
      {
        title: 'Dope Motions',
        link: "",
        video: reel
      },
      {
        title: 'Trick Pilot',
        link: "",
        video: reel
      },
      {
        title: 'Ratin Ray',
        link: "",
        video: reel
      },
      {
        title: 'Arion Frost',
        link: "",
        video: reel
      },
      {
        title: 'Dope Motions',
        link: "",
        video: reel
      }
    ]
  }
];

const HearFromCustomers = () => {
  const feedbacks = customersFeedback[0].feedbacks;  
  const videosRef = useRef([]);
  const [hasInteracted, setHasInteracted] = useState(false);  
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  // Function to handle video interaction on the page to enable hover functionality
  const handleInteraction = () => {
    setHasInteracted(true);
    window.removeEventListener('click', handleInteraction);
    window.removeEventListener('scroll', handleInteraction);
  };

  // Add event listeners to track user interaction
  useEffect(() => {
    window.addEventListener('click', handleInteraction);
    window.addEventListener('scroll', handleInteraction);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('scroll', handleInteraction);
    };
  }, []);

  // Function to play the video on hover
  const handleMouseEnter = (video) => {
    if (hasInteracted) {
      video.muted = false; // Unmute the video
      video.play().catch((error) => {
        console.error('Video play failed due to autoplay restrictions:', error);
      });
      video.classList.add('active');
    }
  };

  // Function to pause the video when the hover ends
  const handleMouseLeave = (video) => {
    video.pause();
    video.muted = true; // Mute the video again
    video.classList.remove('active');
  };

  const handleSlideChange = (swiper) => {
    if (isMobileView) {
      // Pause all videos
      videosRef.current.forEach(video => {
        video.pause();
        video.classList.remove('active');
      });
      // Autoplay the center slide video
      const centerIndex = swiper.activeIndex;
      const video = videosRef.current[centerIndex];
      video.classList.add('active')
      if (video) {
        video.play().catch(error => console.error('Autoplay failed:', error));
      }
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);

    // Autoplay the first video if in mobile view
    if (isMobileView && videosRef.current[0]) {
      const firstVideo = videosRef.current[0];
      firstVideo.play().catch(error => console.error('Autoplay failed:', error));
      firstVideo.classList.add('active');
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileView]);

  return (
    <div className="hear-from-customers">
      <div className="container">
        <h2>Hear from our customers.</h2>
        <div className="slider-area">
          <Swiper
            modules={[Navigation]}
            spaceBetween={16}
            slidesPerView={1.5}
            centeredSlides={true}
            onSlideChange={handleSlideChange}
            navigation={{
              nextEl: '.hear-from-customers .next',
              prevEl: '.hear-from-customers .prev',
            }}
            breakpoints={{
              768: {
                spaceBetween: 21, 
                slidesPerView:4,
                centeredSlides: false
              }
            }}
            className="products-swiper"
          >
            {feedbacks.map((item, index) => (
              <SwiperSlide key={index} className="reel-card">
                <video
                  ref={(el) => (videosRef.current[index] = el)}  // Store reference to each video
                  src={item.video}
                  muted={true}
                  loop
                  playsInline
                  className=""
                  onMouseEnter={(e) => handleMouseEnter(e.target)}  // Call handleMouseEnter on hover
                  onMouseLeave={(e) => handleMouseLeave(e.target)}  // Call handleMouseLeave when hover ends
                />
                <h3>{item.title}</h3>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="slider-arrows">
          <div className="prev"><img width={24} height={24} src={arrow} /></div>
          <div className="next"><img width={24} height={24} src={arrow} /></div>
        </div>
      </div>
    </div>
  );
};

export default HearFromCustomers;
