import React from 'react';
import styles from './categories.module.css';
import category1 from '../../../../assets/arionlight/category1.svg';
import category2 from '../../../../assets/arionlight/category2.svg';
import category3 from '../../../../assets/arionlight/category3.svg';

const categoriesData = [
  {
    title: "Coiled cables",
    image: category1,
    className: styles.categoryCardWhite,
    hasGradientOverlay: true,
  },
  {
    title: "Eclipse 60",
    image: category2,
    isNew: true,
    className: styles.categoryCardGradient,
  },
  {
    title: "Arion Deskmats",
    image: category3,
    className: styles.categoryCardGray,
  },
];

const Categories = () => {
  return (
    <div className={styles.categories}>
      <div className={styles.categoriesContainer}>
        {categoriesData.map((category, index) => (
          <div 
            key={index} 
            className={`${styles.categoryCard} ${category.className}`}
          >
            <div className={styles.cardContent}>
              <h3 className={styles.categoryTitle}>{category.title}</h3>
              <p className={styles.categoryDescription}>Learn more →</p>
            </div>
            <div className={styles.imageContainer}>
              <div 
                className={styles.categoryImage} 
                style={{ backgroundImage: `url(${category.image})` }}
              ></div>
              {category.hasGradientOverlay && <div className={styles.gradientOverlay}></div>}
            </div>
            {category.isNew && <span className={styles.newBadge}>NEW</span>}
          </div>
        ))}
      </div> 
    </div>
  );
};

export default Categories;