import React from "react";
import './InTheBox.css';
import KeyBordImg from '../../../../assets/arionlight/herosectionkeyboard.png';
const InTheBox = () => {
    return (
        <div className="in-the-box">
            <div className="container">
                <h2>In the box</h2>
                <p>Fully Assembled Version</p>
                <img src={KeyBordImg} className="keyboard" alt="Keybord"></img>
                <div className="image-box">
                    <img
                        src="/Footer/ScrewDriver.svg"
                        alt="footerbg"
                        className="screw-driver"
                    />
                    <img src="/Footer/USB.svg" alt="footerbg" />
                    <img
                        src="/Footer/KeySwitchPuller.svg"
                        alt="footerbg"
                    />
                    <img
                        src="/Footer/KeyCapPuller.svg"
                        alt="footerbg"
                    />
                </div>
                <div className="cards">
                    <div className="card">
                        <h3>Keyboard</h3>
                        <p>1x Fully Assembled Keyboard</p>
                        <hr></hr>
                        <p>Including
                            1x Case <br />
                            1x PCB <br />
                            1x Steel Plate <br />
                            1x Sound Absorbing Foam <br />
                            1x Silicone Bottom <br />5 Set of Stabilizers (PCB Screw-In) <br />
                            1 Set of Keycaps (Double-Shot PBT) <br />
                            1 Set of Switches (Gateron G Pro 3.0) <br />
                        </p>
                    </div>
                    <div className="card">
                        <h3>Cable</h3>
                        <p>1x Type-A to Type-C Cable
                        </p>
                        <hr></hr>
                        <h3>Tools</h3>
                        <p>1x Switch Puller <br />
                            1x Keycap Puller <br />
                            1x Screwdriver <br />
                        </p>
                        <hr></hr>
                        <h3>Extras</h3>
                        <p>1x Acrylic Dust Cover <br />
                            3x Switches (K-Pro)<br />
                            Keycaps<br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InTheBox;